<template>
  <footer id="footer">
  	<section data-justify="spaced">
	  	<div class="flow" id="logos-newsletter">
	  		<div id="logos" class="cover">
	  			<img src="@assets/images/Pon_cultura_e_sviluppo.png">
	  			<img src="@assets/images/icpi_logo.png">
	  		</div>
	  		<!-- <div class="stack-horizontal" id="newsletter-form">
	  			<input type="text" class="half-radius" :placeholder="$t('subscribe_newsletter')" name="newsletter_subscription">
	  			<base-button type="tertiary-negative" :text="$t('subscribe')" @clicked="subscribe"/>
	  		</div> -->
        <iframe :src="iframeSrc" title="newsletter" scrolling="no" frameborder="0" id="footer-iframe"></iframe>
	  	</div>
	  	<div class="stack-horizontal menu-voices" data-justify="end">
	  		<ul v-for="(gr, i) in footerFieldsGrouped" :key="i" class="stack-vertical">
	  			<li 
	  				v-for="(field, y) in gr"
	  				:key="y.toString()+i.toString()"
	  				class="pointer capitalize-first"
	  				@click="$router.push({name: field.route ? field.route : field.name})">
	  				{{$tc(field.name, field.quantity)}}
	  			</li>
	  		</ul>
	  	</div>
	  </section>
	  <div class="horizontal-separator" style="border-color: var(--color-line-light);"></div>
	  <section class="stack-horizontal" data-justify="spaced">
  		<ul class="stack-horizontal other-links">
  			<li v-for="(field, i) in footerBottomFields" :key="i" class="pointer capitalize-first"><a :href="field.href" target="_blank" class="text--button"> {{$t(field.name)}}</a></li>
  		</ul>
  		<div class="stack-horizontal" data-gap="medium" data-align="center">
	  		<p class="text--button">{{ $t('footer_copyright') }}</p>
	  		<ul class="stack-horizontal social-links" data-gap="small">
	  			<li v-for="social in socials" class="pointer" @click="social.action"><a :href="social.href" target="_blank"> <base-icon color-fill="white" :name="social.icon"/></a></li>
	  		</ul>
	  	</div>
	  </section>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'InterfaceFooter',
  data() {
  	return {
  		footerFields: [
  			{name: "about", quantity: 1},
  			{name: "explore", quantity: 1},
  			{name: "story", quantity: 2, route: 'stories'},
  			{name: "event", quantity: 2, route: 'events'},
  			{name: "take_part", quantity: 1, route: 'takepart'},
  			{name: "institution", quantity: 2, route: 'institutions'},
  			{name: "press_room", quantity: 1, route: 'pressroom'},
  			// {name: "open_data", quantity: 1, route: 'opendata'},
  			// {name: "api", quantity: 1},
  			{name: "help_titolo_pagina", quantity: 1, route: 'help'},
  		],
  		footerBottomFields: [
				// "site_index",
				{name: "terms", href: "https://www.beniculturali.it/cookie-policy"},
				{name: "privacy_policy", href: "https://www.beniculturali.it/privacy-policy"},
				// {name: "contacts", href: ""}
  		],
  		socials: [
  			{name: 'facebook', icon: 'fb', href: 'https://www.facebook.com/gca.culturalimentare', action: () => {}},
  			// {name: 'instagram', icon: 'instagram', action: () => console.log('share on instagram')},
  			// {name: 'twitter', icon: 'tw', action: () => console.log('share on twitter')},
  		]
  	}
  },
  computed: {
  	...mapState({
  		mobile: state => state.interface.mobile
  	}),
  	footerFieldsGrouped() {
  		return [this.footerFields.slice(0,5), this.footerFields.slice(5, 10)]
  	},
  	iframeSrc() {
			return `${process.env.VUE_APP_BASE_URL}/mailchimp_sub.html?lang=${this.$i18n.locale}`;
  	}
  },
  methods: {
  	subscribe(e) {
  		console.log('subscribe to newsletter');
  	},
  	goToPage(e, field) {
  		console.log('field', field)
  	}
  }
}
</script>

<style lang="scss" src="@css/components/InterfaceFooter.scss"></style>
