<template>
  <div class="page">
    <interface-navbar />
    <keep-alive include="explore">
      <router-view class="view" :id="$route.name" :key="$route.path"></router-view>
    </keep-alive>
    <interface-footer v-if="!hideFooter"/>
  </div>
</template>

<script>
import InterfaceNavbar from "@components/InterfaceNavbar.vue";
import InterfaceFooter from "@components/InterfaceFooter.vue";
import { mapState } from 'vuex';

export default {
  name: 'PageMain',
  components: { InterfaceNavbar, InterfaceFooter },
  computed: {
    ...mapState('interface', ['mobile']),
    hideFooter() {
      return this.$route.name == 'explore' && this.mobile;
    }
  }
}
</script>
