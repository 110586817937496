<template>
  <header id="navbar" class="text--body1 full-width">
    
    <!-- header -->
    <div class="header stack-horizontal full-width bg-white" data-align="center">
      <a href="" @click.prevent><img id="logo" src="@assets/images/geca_m.png" alt="logo" @click="$router.push({name:'home'})"></a>
      
      <!-- nav menu -->
      <div v-if="!overflowing" class="links-container stack-horizontal">
        <router-link :to="{name: link.route}" v-for="link in navbarLinks" :key="link.name" class="link cover">
          <p class="capitalize-first">{{link.name}}</p>
        </router-link>
      </div>

      <!-- search -->
      <div class="navbar_button | pushed-right stack-horizontal | text--body2" data-align="center">
        <base-icon name="search"/>
        <form @submit="searchAll"><input class="capitalize-first" type="text" :placeholder="$t('search_all')" v-model="searchAllString"></form>
        </input>
      </div>
<!--       <div class="navbar_button | cover | text--body2 pointer">
        <p class="capitalize-first">{{$t('login')}}</p>
      </div> -->

      <div v-if="localeEnabled" id="language-selector">
        <div class="icon-container | cover | bg-white pointer" @click="toggleLangDropdown">
          <base-icon name="switch-language" color-fill="black" />
        </div>
        <ul v-if="langDropdown" class="bg-white text--body2 flow bordered" style="--flow-space: var(--s0)">
          <li @click="selectLanguage('it')"><button>Italiano</button></li>
          <li @click="selectLanguage('en')"><button>English</button></li>
        </ul>        
      </div>


      <!-- hamburger -->
      <div class="icon-container | cover | bg-black pointer" :class="{'pushed-right':mobile}" @click="toggleDrawer">
        <base-icon name="menu" color-fill="white" />
      </div>
    </div>

    <!-- drawer -->
    <div class="drawer | bg-white" :class="{opened}">
      <div class="header | bg-black">
        <div class="icon-container | pushed-right cover | bg-black pointer" @click="toggleDrawer">
          <base-icon name="close" color-fill="white" />
        </div>
      </div>

      <div class="flow">
        <template v-for="(linkGroup, i) in links">
          <div v-if="linkGroup.filter(el => !el.disabled).length" :key="i" class="flow">
            <div v-for="link in linkGroup.filter(el => !el.disabled)" :key="link.name" >
              <router-link :to="{name: link.route}" class="capitalize-first">{{link.name}}</router-link>
            </div>
          </div>
        </template>
      </div>

    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import utils from '@js/utils.js'
export default {
  name: 'InterfaceNavbar',
  data() {
    return {
      opened: false,
      langDropdown: false,
      overflowing: false,
      searchAllString: undefined,
      localeEnabled: true
    }
  },
  computed: {
    ...mapState("interface", ["mobile"]),
    links() {

      return [
        [
          {
            name: this.$t('explore'),
            route: "explore"
          },
          {
            name: this.$tc('story', 2),
            route: "stories"
          },
          {
            name: this.$tc('event', 2),
            route: "events"
          },
          {
            name: this.$tc('institution_and_partners'),
            route: "institutions"
          },
          {
            name: this.$t('take_part'),
            route: "takepart"
          },
          {
            name: this.$t('about'),
            route: "about"
          }
        ],
        [
          {
            name: this.$t('press_room'),
            route: "pressroom"
          },
          {
            name: this.$tc('open_data', 2),
            route: "opendata",
            disabled: true
          },
          {
            name: this.$tc('api', 2),
            route: "api",
            disabled: true
          }
        ],
        [
          {
            name: this.$t('help_titolo_pagina'),
            route: "help"
          },
          {
            name: this.$tc('contacts', 2),
            route: "contacts"
          },
          // {
          //   name: this.$tc('profile', 2),
          //   route: "profile"
          // }
        ],
        [
          {
            name: this.$t('search_all'),
            route: "search",
            disabled: !this.mobile
          }
        ]
      ]
    },
    navbarLinks() {
      return this.links[0].filter(el => el.route != 'institutions')
    }
  },
  watch: {
    $route(n,o) {
      if (this.opened) {
        this.toggleDrawer();
      }
      this.searchAllString = undefined;
    }
  },
  methods: {
    toggleDrawer() {
      this.opened = !this.opened;
    },
    onResize() {
      let isOverflown = window.innerWidth <= 1460;//utils.isOverflown(this.$el);

      if (isOverflown && !this.overflowing) {
        this.overflowing = true;
        // console.log("overflowing changed to ",this.overflowing)
      } else if (!isOverflown && this.overflowing) {
        this.overflowing = false;
        // console.log("overflowing changed to ",this.overflowing)
      }
    },
    selectLanguage(lang) {
      this.$i18n.locale = lang;
      this.toggleLangDropdown();
    },
    toggleLangDropdown() {
      this.langDropdown = !this.langDropdown;
    },
    searchAll(event) {
      event.preventDefault();
      this.$router.push({name: 'search', query: {search: this.searchAllString}})
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
}
</script>

<style lang="scss">
  @import '@css/variables.scss';

  #navbar {
    position: fixed;
    top:0;
    z-index: 100;
    --navbar-drawer-width: calc(var(--s6) * 2);
    white-space: nowrap;
    border-bottom: 1px solid var(--color-line-dark);
    // overflow: hidden; 

    .header {
      height: var(--navbar-height);
    }

    #logo {
      width: 10.8125rem;
      margin-right: var(--s4);
      cursor: pointer;
    }

    #language-selector {
      position: relative;
      height: 100%;
      
      ul {
        position: absolute;
        top: 100%;
        padding: var(--s1);
      }
    }

    .navbar_button {
      border-radius: var(--s0);
      background-color: transparent;
      height: 2.25rem;
      padding: 0px var(--s0);
      vertical-align: middle;

      &:first-child {
        width: 14.5rem;
        > input {
          background: url(../assets/icons/search.svg) no-repeat scroll 7px 7px;
          padding-left: 30px;
        }
      }

      &:hover {
        background-color: var(--color-thin);
      }
    }

    .drawer {
      position: fixed;
      width: 0;
      top: 0;
      right: 0;
      min-height: 100vh;
      z-index:1;
      transition: all var(--transition-duration-long) var(--transition-type);
      overflow-x: hidden;
      opacity: 1;
      border-left: 1px solid var(--color-line-dark);

      > div:last-child {
        padding: 2.5rem;

        > div {
          --flow-space: var(--s1);
          padding-bottom: var(--s1);

          &:not(:last-child) {
            border-bottom: 1px solid var(--color-line-ultralight);
          }

          a {
            display: block;
            transition: all var(--transition-duration-short) var(--transition-type);
          }

          a:hover {
            transform: translateX(0.2rem);
            color: var(--color-primary);
          }

        }
      }

      &.opened {
        width: min(100vw, var(--navbar-drawer-width));
      }
    } 

    .icon-container {
      height: 100%;
      width: var(--navbar-height);
      flex-shrink: 0;
      transition: background-color var(--transition-duration) var(--transition-type);

      &.bg-black:hover {
        background-color: var(--color-gray-ultradark);
      }

    }

    .links-container {
      --flow-space-horizontal: 0;
      height: 100%;
    }

    .links-container a p {
      transition: transform .05s var(--transition-type);
    }

    .links-container a:hover p {
      transform: translateY(-0.2rem);
      color: var(--color-primary);
    }

    .link {
      width: 9.375rem;

      a {
        transition: transform var(--transition-duration-short) var(--transition-type);
      }

      &:hover a {
        color: var(--color-primary);
        // transform: translateY(-0.2rem);
      }

      &:hover {
        border-left: 1px solid var(--color-line-dark);
        border-right: 1px solid var(--color-line-dark);
      }

    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (max-width: $breakpoint-sm) {
    #navbar {
      .navbar_button {
        display: none;
      }
    }
  }
</style>
